html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 14px;
}

.title {
  font-size: 16px;
}

#root {
  display: flex;
}

.up {
  color: green !important;
}
.down {
  color: red !important;
}

.placeholder {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  min-height: 18px;
  min-width: 18px;
}
.placeholder:after {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: #f6f7f8;
  border-radius: 8px;
}
.placeholder:before {
  z-index: 2;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* transform: rotate(45deg); */
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.05),
    transparent
  );
  animation: load 1.5s linear infinite;
  animation-fill-mode: forwards;
}

@keyframes load {
  0% {
    /* top: -200%; */
    left: -100%;
  }
  100% {
    /* top: 200%; */
    left: 100%;
  }
}

/* * {
  transition: all 0.3s;
} */

/* .border-left {
  position: absolute;
  top: -3px;
  left: -15px;
  right: -15px;
  height: calc(100%);
  width: calc(100% + 30px);
  z-index: 1;
  background-color: white;
} */

/* .border-right {
  position: absolute;
  top: -2px;
  right: -15px;
  left: -15px;
  height: calc(100% + 2px);
  width: calc(100% + 30px);
  background-color: white;
  z-index: 1;
} */
